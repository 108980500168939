.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    background: white;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
    align-items: stretch;
    flex: 1 0 auto;
}

.header {
    height: 90px;
    width: 100%;
    background: #772583;
    text-align: center;
    display: flex;
    padding: 15px;
    align-items: center;
    vertical-align: middle;
    flex: 0 0 auto;
}

.header div {
    width: 100%;
}

.header h1 {
    font-size: 18px;
    font-weight: bold;
    padding-left: 2rem;
    padding-right: 1rem;
    font-weight: normal;
    text-shadow: 0 0 1px white;
    color: white;
}

.breadcrumbContainer {
    height:30px;
    padding-right: 15px;
    padding-top: 7px;
    background:#E10098;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row ;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    }

.breadcrumbLeft {
    text-align: right;
    height:30px;
}

.breadcrumbRight {
    text-align: right;
    height:30px;
}

.breadcrumbContainer a {
    color: darken(white, 10%);
    text-decoration: none;
    font-size: 14px;
    text-rendering: optimizeLegibility;
}

.breadcrumbContainer a:before {
    content: "|";
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    top: -2px;
    font-weight: bold;
    color: darken(white, 10%) !important;
}

.breadcrumbContainer a:first-child:before {
    content: "";
}

.breadcrumbContainer a:hover {
    color: white;
}

.main {
    width: 100%;
    padding: 0.5rem 1rem 1rem;
    display:inline-block;
    vertical-align:top;
    flex: 1 0 auto;
}

.footer {
    width: 100%;
    height: 100px;
    background: #772583;
    margin: 0;
    border: 0;
    color: white;
    padding: 15px;
    vertical-align: middle;
    flex: 0 0 auto;
}

.rightPanel {
    float:right;
    width: 100%;
}