$feature-flags: (
        grid-columns-16: true
);

$prefix: 'bx';

$aecc-color-template: (
  //interactive-01: #0062ff,
        interactive-01: #772583,
        interactive-02: #83786F,
        interactive-03: #772583,
        interactive-04: #772583,
        ui-background: #ffffff,
        ui-01: #f3f3f3,
        ui-02: #ffffff,
        ui-03: #dcdcdc,
        ui-04: #8c8c8c,
        ui-05: #171717,
        text-01: #171717,
        text-02: #565656,
        text-03: #8c8c8c,
        text-04: #ffffff,
        icon-01: #171717,
        icon-02: #565656,
        icon-03: #ffffff,
        link-01: #772583,
        link-02: #772583,
        field-01: #f3f3f3,
        field-02: #ffffff,
        inverse-01: #ffffff,
        inverse-02: #3d3d3d,
        support-01: #da1e28,
        support-02: #24a148,
        support-03: #fdd13a,
        support-04: #772583,
        inverse-support-01: #fb4b53,
        inverse-support-02: #3dbb61,
        inverse-support-03: #fdd13a,
        inverse-support-04: #772583,
        overlay-01: rgba(23, 23, 23, 0.5),
        focus: #772583,
//        hover-primary: lighten(#772583, 10%),
        hover-primary: #e10098,
//        hover-primary: lighten(#772583, 10%),
        active-primary: #772583,
        hover-primary-text: #772583,
        hover-secondary: darken(#83786F, 10%),
//        hover-secondary: #bfb8af,

        active-secondary: #6f6f6f,
        hover-tertiary: #772583,
        active-tertiary: #772583,
        hover-ui: #e5e5e5,
        active-ui: #bebebe,
        selected-ui: #dcdcdc,
        hover-selected-ui: #cacaca,
        hover-danger: #ba1b23,
        active-danger: #750e13,
        hover-row: #e5e5e5,
        visited-link: #8a3ffc,
        disabled-01: #f3f3f3,
        disabled-02: #bebebe,
        disabled-03: #8c8c8c,
        highlight: #c9deff,
        skeleton-01: #e5e5e5,
        skeleton-02: #bebebe,
  //        brand-01: #0062ff,
        brand-01: #772583,
        brand-02: #171717,
  //        brand-03: #0062ff,
        brand-03: #772583,
        active-01: #bebebe,
        hover-field: #e5e5e5,
) !default;


$css--font-face: true;
$css--helpers: true;
$css--body: true;
$css--use-layer: true;
$css--reset: true;
$css--typography: true;
$css--plex: true;

@import '~@carbon/themes/scss/themes';

$carbon--theme: $aecc-color-template;
@include carbon--theme();

//@include aecc-color-template();


//@import '~carbon-components/scss/globals/scss/styles';
@import '~carbon-components/scss/components/button/button';
@import '~carbon-components/scss/components/accordion/accordion';
@import '~carbon-components/scss/components/select/select';
@import '~carbon-components/scss/components/tabs/tabs';
@import '~carbon-components/scss/components/date-picker/date-picker';


/*
@import '~carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import '~carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import '~carbon-components/scss/globals/scss/typography.scss';
@import '~carbon-components/scss/globals/scss/vars.scss';
*/

body {
  padding: 0;
  margin: 0;
  background-color: #5f6265;
//  background-color: darken(#83786F, 20%);
//  background-color: #BFB8AF;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: lighten(black, 10%);
  text-rendering: optimizeLegibility;
}

h3 {
  font-size: 18px;
  color: lighten(black, 10%);
  font-weight: bold;
}

.#{$prefix}--tabs--scrollable .#{$prefix}--tabs--scrollable__nav-item--selected
.#{$prefix}--tabs--scrollable__nav-link,
.#{$prefix}--tabs--scrollable .#{$prefix}--tabs--scrollable__nav-item--selected
.#{$prefix}--tabs--scrollable__nav-link:focus,
.#{$prefix}--tabs--scrollable .#{$prefix}--tabs--scrollable__nav-item--selected
.#{$prefix}--tabs--scrollable__nav-link:active {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.16px;
  border-bottom: 2px solid #772583;
  color: #161616;
}

.#{$prefix}--tabs--scrollable.#{$prefix}--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected,
.#{$prefix}--tabs--scrollable.#{$prefix}--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected:hover {
  background-color: #f4f4f4;
}

.#{$prefix}--tabs--scrollable.bx--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected .#{$prefix}--tabs--scrollable__nav-link:focus,
.#{$prefix}--tabs--scrollable.bx--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected .#{$prefix}--tabs--scrollable__nav-link:active,
.#{$prefix}--tabs--scrollable.bx--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected:hover .#{$prefix}--tabs--scrollable__nav-link:focus,
.#{$prefix}--tabs--scrollable.bx--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected:hover .#{$prefix}--tabs--scrollable__nav-link:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.#{$prefix}--tabs--scrollable.#{$prefix}--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected
.#{$prefix}--tabs--scrollable__nav-link {
/*  -webkit-box-shadow: inset 0 2px 0 0 #772583;
  box-shadow: inset 0 2px 0 0 #772583;

 */
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  line-height: calc(3rem - (0.5rem * 2));
}

.#{$prefix}--tabs--scrollable.#{$prefix}--tabs--scrollable--light.#{$prefix}--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected,
.#{$prefix}--tabs--scrollable.#{$prefix}--tabs--scrollable--light.#{$prefix}--tabs--scrollable--container .#{$prefix}--tabs--scrollable__nav-item--selected:hover {
  background-color: #ffffff;
}

.#{$prefix}--btn--primary:focus, .#{$prefix}--btn--secondary:focus {
  box-shadow: -0.0625rem 0 0 0;
  border-color: transparent;
}

.#{$prefix}--btn:first-of-type:not(:focus) {
  box-shadow: -0.0625rem 0 0 0 !important;
  border-color: transparent;
}


.#{$prefix}--btn--surround {
  text-decoration: none;
  margin-bottom: 15px;
}

.#{$prefix}--btn--surround div {
  min-height: 3rem;
}

.#{$prefix}--btn--wide {
    min-width: 12.25rem;
    min-height: 3rem;
  }


.#{$prefix}--btn--disabled {
  color: white;
}


.#{$prefix}--btn-set .#{$prefix}--btn.#{$prefix}--btn--disabled {
  box-shadow: rem(-1px) 0 0 0 white;

  &:first-of-type {
    box-shadow: none;
  }
}


#serviceOptions, #secondaryOptions {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

#serviceOptions .#{$prefix}--btn, #serviceOptions .#{$prefix}--btn--disabled {
  min-width: 10rem;
  max-width: 10rem;
  color:white;
}

.#{$prefix}--tabs--scrollable .#{$prefix}--tabs--scrollable__nav-link {
  display: inline-block;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  overflow: hidden;
  width: calc((968px - 40px) / 7);
  padding: 0.75rem 1rem 0.5rem;
  border-bottom: 2px solid #e0e0e0;
  color: #525252;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  -webkit-transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  white-space: nowrap;
}


.#{$prefix}--date-picker.#{$prefix}--date-picker--single
.#{$prefix}--date-picker__input {
  width: auto;
}


.#{$prefix}--date-picker__input {


  position: relative;
  display: block;
  height: rem(45px);
  padding: 0 $carbon--spacing-05;
  border: none;
  border-bottom: 1px solid $border-strong;
  background-color: $field;
  color: $text-primary;
  transition: $duration--fast-01 motion(standard, productive) all;

  &:focus,
  &.#{$prefix}--focused {
    @include focus-outline('outline');
  }

  &:disabled {
    border-bottom: 1px solid transparent;
    background-color: $field-disabled;
    color: $text-disabled;
    cursor: not-allowed;
  }

  &:disabled::placeholder {
    color: $text-disabled;
  }

  &:disabled:hover {
    border-bottom: 1px solid transparent;
  }

  &::placeholder {
    @include placeholder-colors;
  }
}

.MuiFormControl-root {
  border-left: 1px solid transparent !important;

  border-right: 1px solid transparent !important;

  border-top: 1px solid transparent !important;

  border-bottom: 1px solid #cccccc !important;

  padding: 25px 15px 10px 15px !important;
  margin: 0 !important;
  width: 100% !important;
  font-size: 1rem !important;
  outline: 0px solid transparent !important;
}

.MuiFormControl-root:hover {
  border-color: #e10098 !important;



}


.MuiInputBase-input:hover {
  border-color:#e0268c;
}

.MuiInputBase-input {

  padding: 0 !important;
}

.MuiInput-underline::before {
  border: none !important;
  content: "" !important;
}

.MuiInput-underline::after {
  border: none !important;
  content: "" !important;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
  color: #e10098;
}

.Mui-focusVisible {
  background-color: transparent !important;
  transition: none !important;
}

.bx--btn--icon-only--right--button {
  outline: 1px solid white !important;
}